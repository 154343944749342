import React from 'react';
import { motion } from 'framer-motion';

import Layout from '../components/layout';
import SEO from '../components/seo';

import '../styles/global.scss';
import '../styles/tos.scss';

const TermsOfService = () => (
    <Layout>
        <SEO title="Terms Of Service" />
        <div className="container pageHero">
            <motion.h1
                initial={{
                    opacity: 0,
                    scale: 0.8,
                    y: 20,
                }}
                animate={{
                    scale: 1,
                    opacity: 1,
                    y: 0,
                }}
                transition={{
                    duration: 1,
                }}
                className="pageHeadline"
            >
                Terms of Service for the Use of Earth Hero application, website, and other platforms
            </motion.h1>

            <span className="tos-text">
                Thank you for utilizing Earth Hero. We hope the content and tools provided by the
                Earth Hero app, website, and social media sites (referred to here as the{' '}
                <strong>Earth Hero Platform</strong>) help make it easy for you to take climate
                actions. Earth Hero is a non-profit, all-volunteer group that formed in 2019. We
                seek to provide the best information regarding actions to address the climate
                emergency, carbon emissions, and to increase your social impact.
            </span>

            <span className="tos-text">
                By accessing and using the Earth Hero Platform you agree to comply with the Terms of
                Service that are in effect. These Terms of Service constitute a legally binding
                agreement made between you, whether personally or on behalf of an entity (“you”) and
                Earth Hero (“we,” “us” or “our”), concerning your access to and use of the Earth
                Hero app and website, as well as any other media form, media channel, mobile website
                or mobile application related, linked, or otherwise connected thereto.
            </span>

            <span className="tos-text">
                You agree that by accessing the Earth Hero Platform, you have read, understood, and
                agree to be bound by all of these Terms of Service. If you do not agree with all of
                these Terms of Service, then you are expressly prohibited from using the Platform
                and you must discontinue use immediately.
            </span>

            <span className="tos-text">
                We reserve the right to modify these Terms of Service from time to time. We will
                make reasonable efforts to publish such changes here and on EarthHero.org. We will
                alert you about any changes by updating the “Last updated” date of these Terms of
                Service, and you waive any right to receive specific notice of each such change. It
                is your responsibility to periodically review these Terms of Service to stay
                informed of updates. You will be subject to, and will be deemed to have been made
                aware of and to have accepted, the changes in any revised Terms of Service by your
                continued use of the Platform after the date such revised Terms of Service are
                posted. Your continued use of Earth Hero constitutes your acceptance of this Terms
                of Use. Please check this Terms of Service for any such updates.
            </span>

            <span className="tos-text-underline">Disclaimers:</span>

            <ol>
                <li>
                    <span className="tos-text">
                        <strong>Availability:</strong> Earth Hero reserves the right at its own
                        discretion to perform maintenance procedures on its platforms or to perform
                        other actions that might impact service availability. Earth Hero is not
                        legally liable for any damages, monetary or otherwise, that may result from
                        service unavailability.
                    </span>
                </li>

                <li>
                    <span className="tos-text">
                        <strong>Local Compliance:</strong> The information provided on the Platform
                        is not intended for distribution to or use by any person or entity in any
                        jurisdiction or country where such distribution or use would be contrary to
                        law or regulation or which would subject us to any registration requirement
                        within such jurisdiction or country. Accordingly, those persons who choose
                        to access the Platform from other locations do so on their own initiative
                        and are solely responsible for compliance with local laws, if and to the
                        extent local laws are applicable.
                    </span>
                </li>

                <li>
                    <span className="tos-text">
                        <strong>Age:</strong> The Earth Hero platform intended for users who are at
                        least 13 years of age. All users who are minors in the jurisdiction in which
                        they reside (generally under the age of 18) must have the permission of, and
                        be directly supervised by, their parent or guardian to use the Platform. If
                        you are a minor, you must have your parent or guardian read and agree to
                        these Terms of Service prior to you using the Platform.
                    </span>
                </li>

                <li>
                    <span className="tos-text">
                        <strong>User Representations:</strong> By using the Platform, you represent
                        and warrant that: (1) all registration information you submit will be true,
                        accurate, current, and complete, (2) you will maintain the accuracy of such
                        information and promptly update such registration information as necessary,
                        (3) you have the legal capacity and you agree to comply with these Terms of
                        Service, (4) you are not under the age of 13, (5) not a minor in the
                        jurisdiction in which you reside, or if a minor, you have received parental
                        permission to use the Platform, (6) you will not access the Platform through
                        automated or non-human means, whether through a bot, script, or otherwise,
                        (7) you will not use the Platform for any illegal or unauthorized purpose,
                        (8) your use of the Platform will not violate any applicable law or
                        regulation. If you provide any information that is untrue, inaccurate, not
                        current, or incomplete, we have the right to suspend or terminate your
                        account and refuse any and all current or future use of the Platform (or any
                        portion thereof).
                    </span>
                </li>

                <li>
                    <span className="tos-text">
                        <strong>Data Accuracy and Completeness:</strong> Earth Hero does not warrant
                        against nor accepts legal responsibility for the accuracy, completeness, or
                        usefulness of any data, information, or media resources (called here
                        content) available through any of its platforms. Climate science regarding
                        the carbon emissions mitigation of actions is a rapidly improving field, but
                        further science is needed on many aspects. Also, any added views or opinions
                        of the volunteer authors expressed with each action and its content do not
                        necessarily state or reflect those of Earth Hero. Users are cautioned to
                        consider carefully the provisional nature of the content before using them
                        for decisions that concern personal or public health and safety or for the
                        conduct of business that involves monetary or operational consequences.
                    </span>
                </li>

                <li>
                    <span className="tos-text">
                        <strong>User Registration:</strong> You may be required to register with the
                        Platform to use all features. You agree to keep your password confidential
                        and will be responsible for all use of your account and password. We reserve
                        the right to remove, reclaim, or change a username you select if we
                        determine, in our sole discretion, that such username is inappropriate,
                        obscene, or otherwise objectionable.
                    </span>
                </li>

                <li>
                    <span className="tos-text">
                        <strong>External links:</strong> Some content may provide links to other
                        internet sites and tools that belong to government agencies or private
                        groups, for the convenience of the user. Earth Hero is not responsible for
                        the availability or content of these external sites, nor does Earth Hero
                        endorse, warrant, or guarantee any products, services, or information
                        described or offered at these other Internet sites. Further, users cannot
                        assume that the external sites will abide by the same privacy policy to
                        which Earth Hero adheres. It is the responsibility of the user to examine
                        the terms of use of such sites and tools.
                    </span>
                </li>

                <li>
                    <span className="tos-text">
                        <strong>Privacy:</strong> See the full policy located at{' '}
                        <a href="https://www.earthhero.org/privacy-policy">
                            https://www.earthhero.org/privacy-policy
                        </a>
                    </span>
                </li>

                <li>
                    <span className="tos-text">
                        <strong>User Generated Contributions:</strong> The Platform may invite you
                        to chat, contribute to, or participate in blogs, message boards, online
                        forums, and other functionality, and may provide you with the opportunity to
                        create, submit, post, display, transmit, perform, publish, distribute, or
                        broadcast content and materials to us or on the Platform, including but not
                        limited to text, writings, video, audio, photographs, graphics, comments,
                        suggestions, or personal information or other material (collectively,
                        “Contributions”).
                    </span>

                    <span className="tos-text-subtext">
                        Contributions may be viewable by other users of the Platform and through
                        third-party websites. As such, any Contributions you transmit may be treated
                        as non-confidential and non-proprietary. When you create or make available
                        any Contributions, you thereby represent and warrant that:
                    </span>

                    <span className="tos-text-subtext">
                        The creation, distribution, transmission, public display, or performance,
                        and the accessing, downloading, or copying of your Contributions do not and
                        will not infringe the proprietary rights, including but not limited to the
                        copyright, patent, trademark, trade secret, or moral rights of any third
                        party.
                    </span>

                    <span className="tos-text-subtext">
                        You are the creator and owner of or have the necessary licenses, rights,
                        consents, releases, and permissions to use and to authorize us, the
                        Platform, and other users of the Platform to use your Contributions in any
                        manner contemplated by the Platform and these Terms of Service.
                    </span>

                    <span className="tos-text-subtext">
                        You have the written consent, release, and/or permission of each and every
                        identifiable individual person in your Contributions to use the name or
                        likeness of each and every such identifiable individual person to enable
                        inclusion and use of your Contributions in any manner contemplated by the
                        Platform and these Terms of Service.
                    </span>

                    <ul>
                        <li>Your Contributions are not false, inaccurate, or misleading.</li>
                        <li>
                            Your Contributions are not unsolicited or unauthorized advertising,
                            promotional materials, pyramid schemes, chain letters, spam, mass
                            mailings, or other forms of solicitation.
                        </li>
                        <li>
                            Your Contributions are not obscene, lewd, lascivious, filthy, violent,
                            harassing, libelous, slanderous, or otherwise objectionable (as
                            determined by us).
                        </li>
                        <li>
                            Your Contributions do not ridicule, mock, disparage, intimidate, or
                            abuse anyone.
                        </li>
                        <li>
                            Your Contributions do not advocate the violent overthrow of any
                            government or incite, encourage, or threaten physical harm against
                            another.
                        </li>
                        <li>
                            Your Contributions do not violate any applicable law, regulation, or
                            rule.
                        </li>
                        <li>
                            Your Contributions do not violate the privacy or publicity rights of any
                            third party.
                        </li>
                        <li>
                            Your Contributions do not contain any material that solicits personal
                            information from anyone under the age of 18 or exploits people under the
                            age of 18 in a sexual or violent manner.
                        </li>
                        <li>
                            Your Contributions do not violate any federal, state, or international
                            law concerning child pornography, or otherwise intended to protect the
                            health or well-being of minors.
                        </li>
                        <li>
                            Your Contributions do not include any offensive comments that are
                            connected to race, national origin, gender, sexual preference, or
                            physical handicap.
                        </li>
                        <li>
                            Your Contributions do not otherwise violate, or link to material that
                            violates, any provision of these Terms of Service, or any applicable law
                            or regulation.
                        </li>
                    </ul>
                    <span className="tos-text-subtext">
                        Any use of the Platform in violation of the foregoing violates these Terms
                        of Service and may result in, among other things, termination or suspension
                        of your rights to use the Platform.
                    </span>
                </li>

                <li>
                    <span className="tos-text">
                        <strong>Responsibility and Liability for Content:</strong> You agree that
                        you are making use of the Earth Hero Platforms at your own risk. Earth Hero
                        does not warrant, does not accept legal responsibility, and will be held
                        harmless for any and all out-of-pocket costs arising from use of the Earth
                        Hero content on its platforms, following U.S. Federal and international
                        laws. Conclusions drawn from or actions undertaken on the basis of the
                        content provided on Earth Hero platforms are the sole responsibility of the
                        user. Earth Hero is not liable, and you agree not to hold us responsible,
                        for any damages or losses (including, but not limited to, loss of money,
                        goodwill or reputation, profits, or other intangible losses or any special,
                        indirect, or consequential damages) resulting directly or indirectly from
                        these Terms and Service, including but not limited to:
                    </span>
                    <ul>
                        <li>Your use of, or your inability to use the Earth Hero Platforms;</li>
                        {/* <li> */}
                        {/* Guidance provided by us; */}
                        {/* </li> */}
                        <li>Delays in or disruptions to the Earth Hero Platforms;</li>
                        <li>
                            Viruses or other malicious software obtained by accessing, or linking
                            to, the Earth Hero Platforms;
                        </li>
                        <li>
                            Glitches, bugs, errors, or inaccuracies of any kind on or in the Earth
                            Hero Platforms;
                        </li>
                        <li>Guidance provided by us;</li>
                        <li>
                            Damage to your hardware device from the use of the Earth Hero Platforms
                        </li>
                    </ul>
                </li>

                <li>
                    <span className="tos-text">
                        <strong>Contribution License:</strong> By posting your Contributions to any
                        part of the Platform, or making Contributions accessible to the Platform by
                        linking your account from the Platform to any of your social networking
                        accounts, you automatically grant, and you represent and warrant that you
                        have the right to grant, to us an unrestricted, unlimited, irrevocable,
                        perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide
                        right, and license to host, use, copy, reproduce, disclose, sell, resell,
                        publish, broadcast, retitle, archive, store, cache, publicly perform,
                        publicly display, reformat, translate, transmit, excerpt (in whole or in
                        part), and distribute such Contributions (including, without limitation,
                        your image and voice) for any purpose, commercial, advertising, or
                        otherwise, and to prepare derivative works of, or incorporate into other
                        works, such Contributions, and grant and authorize sublicenses of the
                        foregoing. The use and distribution may occur in any media formats and
                        through any media channels.
                    </span>

                    <span className="tos-text-subtext">
                        This license will apply to any form, media, or technology now known or
                        hereafter developed, and includes our use of your name, company name, and
                        franchise name, as applicable, and any of the trademarks, service marks,
                        trade names, logos, and personal and commercial images you provide. You
                        waive all moral rights in your Contributions, and you warrant that moral
                        rights have not otherwise been asserted in your Contributions.
                    </span>

                    <span className="tos-text-subtext">
                        We do not assert any ownership over your Contributions. You retain full
                        ownership of all of your Contributions and any intellectual property rights
                        or other proprietary rights associated with your Contributions. We are not
                        liable for any statements or representations in your Contributions provided
                        by you in any area on the Platform.
                    </span>

                    <span className="tos-text-subtext">
                        You are solely responsible for your Contributions to the Platform and you
                        expressly agree to exonerate us from any and all responsibility and to
                        refrain from any legal action against us regarding your Contributions.
                    </span>

                    <span className="tos-text-subtext">
                        We have the right, in our sole and absolute discretion, (1) to edit, redact,
                        or otherwise change any Contributions; (2) to re-categorize any
                        Contributions to place them in more appropriate locations on the Platform;
                        and (3) to pre-screen or delete any Contributions at any time and for any
                        reason, without notice. We have no obligation to monitor your Contributions.
                    </span>
                </li>

                <li>
                    <span className="tos-text">
                        <strong>Unauthorized Access:</strong> Any unauthorized access to or use of
                        the Earth Hero platforms and underlying systems is prohibited and is subject
                        to criminal and civil penalties under U.S. Federal Laws.
                    </span>
                </li>

                <li>
                    <span className="tos-text">
                        <strong>Prohibited Activities:</strong> You may not access or use the
                        Platform for any purpose other than that for which we make the Platform
                        available. The Platform may not be used in connection with any commercial
                        endeavors except those that are specifically endorsed or approved by us. As
                        a user of the Platform, you agree not to (1) Systematically retrieve data or
                        other content from the Platform to create or compile, directly or
                        indirectly, a collection, compilation, database, or directory without
                        written permission from us. (2) Make any unauthorized use of the Platform,
                        including collecting usernames and/or email addresses of users by electronic
                        or other means for the purpose of sending unsolicited email, or creating
                        user accounts by automated means or under false pretenses. (3) Use the
                        Platform to advertise or offer to sell goods and services.(4) Circumvent,
                        disable, or otherwise interfere with security-related features of the
                        Platform, including features that prevent or restrict the use or copying of
                        any Content or enforce limitations on the use of the Platform and/or the
                        Content contained therein. (5) Engage in unauthorized framing of or linking
                        to the Platform. (6) Trick, defraud, or mislead us and other users,
                        especially in any attempt to learn sensitive account information such as
                        user passwords; (7) Make improper use of our support services or submit
                        false reports of abuse or misconduct. (8) Engage in any automated use of the
                        system, such as using scripts to send comments or messages, or using any
                        data mining, robots, or similar data gathering and extraction tools. (9)
                        Interfere with, disrupt, or create an undue burden on the Platform or the
                        networks or services connected to the Platform. (10) Attempt to impersonate
                        another user or person or use the username of another user. (11) Sell or
                        otherwise transfer your profile. (12) Use any information obtained from the
                        Platform in order to harass, abuse, or harm another person. (13) use the
                        Platform as part of any effort to compete with us or otherwise use the
                        Platform and/or the Content for any revenue-generating endeavor or
                        commercial enterprise. (14) Decipher, decompile, disassemble, or reverse
                        engineer any of the software comprising or in any way making up a part of
                        the Platform. (15) Attempt to bypass any measures of the Platform designed
                        to prevent or restrict access to the Platform, or any portion of the
                        Platform. (16) Harass, annoy, intimidate, or threaten any of our employees
                        or agents engaged in providing any portion of the Platform to you. (17)
                        Delete the copyright or other proprietary rights notice from any Content.
                        (18) Copy or adapt the Platform’s software, including but not limited to
                        Flash, PHP, HTML, JavaScript, or other code. (19) Upload or transmit (or
                        attempt to upload or to transmit) viruses, Trojan horses, or other material,
                        including excessive use of capital letters and spamming (continuous posting
                        of repetitive text), that interferes with any party’s uninterrupted use and
                        enjoyment of the Platform or modifies, impairs, disrupts, alters, or
                        interferes with the use, features, functions, operation, or maintenance of
                        the Platform. (20) Upload or transmit (or attempt to upload or to transmit)
                        any material that acts as a passive or active information collection or
                        transmission mechanism, including without limitation, clear graphics
                        interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or other
                        similar devices (sometimes referred to as “spyware” or “passive collection
                        mechanisms” or “pcms”). (21) Except as may be the result of standard search
                        engine or Internet browser usage, use, launch, develop, or distribute any
                        automated system, including without limitation, any spider, robot, cheat
                        utility, scraper, or offline reader that accesses the Platform, or using or
                        launching any unauthorized script or other software. (22) Disparage,
                        tarnish, or otherwise harm, in our opinion, us and/or the Platform. (23) Use
                        the Platform in a manner inconsistent with any applicable laws or
                        regulations.
                    </span>
                </li>

                <li>
                    <span className="tos-text">
                        <strong>Mobile Application License:</strong> Use License - If you access the
                        Platform via a mobile application, then we grant you a revocable,
                        non-exclusive, non-transferable, limited right to install and use the mobile
                        application on wireless electronic devices owned or controlled by you, and
                        to access and use the mobile application on such devices strictly in
                        accordance with the Terms of Service of this mobile application license
                        contained in these Terms of Service.
                    </span>
                    <span className="tos-text-subtext">You shall not:</span>

                    <span className="tos-text-subtext">
                        (1) decompile, reverse engineer, disassemble, attempt to derive the source
                        code of, or decrypt the application;
                    </span>

                    <span className="tos-text-subtext">
                        (2) make any modification, adaptation, improvement, enhancement,
                        translation, or derivative work from the application;
                    </span>

                    <span className="tos-text-subtext">
                        (3) violate any applicable laws, rules, or regulations in connection with
                        your access or use of the application;
                    </span>

                    <span className="tos-text-subtext">
                        (4) remove, alter, or obscure any proprietary notice (including any notice
                        of copyright or trademark) posted by us or the licensors of the application;
                    </span>

                    <span className="tos-text-subtext">
                        (5) use the application for any revenue generating endeavor, commercial
                        enterprise, or other purpose for which it is not designed or intended;
                    </span>

                    <span className="tos-text-subtext">
                        (6) make the application available over a network or other environment
                        permitting access or use by multiple devices or users at the same time;
                    </span>

                    <span className="tos-text-subtext">
                        (7) use the application for creating a product, service, or software that
                        is, directly or indirectly, competitive with or in any way a substitute for
                        the application;
                    </span>

                    <span className="tos-text-subtext">
                        (8) use the application to send automated queries to any website or to send
                        any unsolicited commercial e-mail;
                    </span>

                    <span className="tos-text-subtext">
                        (9) use any proprietary information or any of our interfaces or our other
                        intellectual property in the design, development, manufacture, licensing, or
                        distribution of any applications, accessories, or devices for use with the
                        application.
                    </span>

                    <span className="tos-text-subtext">Apple and Android Devices</span>

                    <span className="tos-text-subtext">
                        The following terms apply when you use a mobile application obtained from
                        either the Apple Store or Google Play (each an “App Distributor”) to access
                        the Platform:
                    </span>

                    <span className="tos-text-subtext">
                        (1) the license granted to you for our mobile application is limited to a
                        non-transferable license to use the application on a device that utilizes
                        the Apple iOS or Android operating systems, as applicable, and in accordance
                        with the usage rules set forth in the applicable App Distributor’s terms of
                        service;
                    </span>

                    <span className="tos-text-subtext">
                        (2) we are responsible for providing any maintenance and support services
                        with respect to the mobile application as specified in the Terms of Service
                        of this mobile application license contained in these Terms of Service or as
                        otherwise required under applicable law, and you acknowledge that each App
                        Distributor has no obligation whatsoever to furnish any maintenance and
                        support services with respect to the mobile application;
                    </span>

                    <span className="tos-text-subtext">
                        (3) in the event of any failure of the mobile application to conform to any
                        applicable warranty, you may notify the applicable App Distributor, and the
                        App Distributor, in accordance with its terms and policies, may refund the
                        purchase price, if any, paid for the mobile application, and to the maximum
                        extent permitted by applicable law, the App Distributor will have no other
                        warranty obligation whatsoever with respect to the mobile application;
                    </span>

                    <span className="tos-text-subtext">
                        (4) you represent and warrant that (i) you are not located in a country that
                        is subject to a U.S. government embargo, or that has been designated by the
                        U.S. government as a “terrorist supporting” country and (ii) you are not
                        listed on any U.S. government list of prohibited or restricted parties;
                    </span>

                    <span className="tos-text-subtext">
                        (5) you must comply with applicable third-party terms of agreement when
                        using the mobile application, e.g., if you have a VoIP application, then you
                        must not be in violation of their wireless data service agreement when using
                        the mobile application;
                    </span>

                    <span className="tos-text-subtext">
                        (6) you acknowledge and agree that the App Distributors are third-party
                        beneficiaries of the Terms of Service in this mobile application license
                        contained in these Terms of Service, and that each App Distributor will have
                        the right (and will be deemed to have accepted the right) to enforce the
                        Terms of Service in this mobile application license contained in these Terms
                        of Service against you as a third-party beneficiary thereof.
                    </span>
                </li>

                <li>
                    <span className="tos-text">
                        <strong>Submissions:</strong> You acknowledge and agree that any questions,
                        comments, suggestions, ideas, feedback, or other information regarding the
                        Platform (“Submissions”) provided by you to us are non-confidential and
                        shall become our sole property. We shall own exclusive rights, including all
                        intellectual property rights, and shall be entitled to the unrestricted use
                        and dissemination of these Submissions for any lawful purpose, commercial or
                        otherwise, without acknowledgment or compensation to you.
                    </span>

                    <span className="tos-text-subtext">
                        You hereby waive all moral rights to any such Submissions, and you hereby
                        warrant that any such Submissions are original with you or that you have the
                        right to submit such Submissions. You agree there shall be no recourse
                        against us for any alleged or actual infringement or misappropriation of any
                        proprietary right in your Submissions.
                    </span>
                </li>

                <li>
                    <span className="tos-text">
                        <strong>Social Media:</strong> As part of the functionality of the Platform,
                        you may link your account with online accounts you have with third-party
                        service providers (each such account, a “Third-Party Account”) by either:
                        (1) providing your Third-Party Account login information through the
                        Platform; or (2) allowing us to access your Third-Party Account, as is
                        permitted under the applicable Terms of Service that govern your use of each
                        Third-Party Account.
                    </span>

                    <span className="tos-text-subtext">
                        You represent and warrant that you are entitled to disclose your Third-Party
                        Account login information to us and/or grant us access to your Third-Party
                        Account, without breach by you of any of the Terms of Service that govern
                        your use of the applicable Third-Party Account, and without obligating us to
                        pay any fees or making us subject to any usage limitations imposed by the
                        third-party service provider of the Third-Party Account.
                    </span>

                    <span className="tos-text-subtext">
                        By granting us access to any Third-Party Accounts, you understand that (1)
                        we may access, make available, and store (if applicable) any content that
                        you have provided to and stored in your Third-Party Account (the “Social
                        Network Content”) so that it is available on and through the Platform via
                        your account, including without limitation any friend lists and (2) we may
                        submit to and receive from your Third-Party Account additional information
                        to the extent you are notified when you link your account with the
                        Third-Party Account.
                    </span>

                    <span className="tos-text-subtext">
                        Depending on the Third-Party Accounts you choose and subject to the privacy
                        settings that you have set in such Third-Party Accounts, personally
                        identifiable information that you post to your Third-Party Accounts may be
                        available on and through your account on the Platform.
                    </span>

                    <span className="tos-text-subtext">
                        Please note that if a Third-Party Account or associated service becomes
                        unavailable or our access to such Third-Party Account is terminated by the
                        third-party service provider, then Social Network Content may no longer be
                        available on and through the Platform. You will have the ability to disable
                        the connection between your account on the Platform and your Third-Party
                        Accounts at any time.
                    </span>

                    <span className="tos-text-subtext">
                        PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS
                        ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR
                        AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS.
                    </span>

                    <span className="tos-text-subtext">
                        We make no effort to review any Social Network Content for any purpose,
                        including but not limited to, for accuracy, legality, or non-infringement,
                        and we are not responsible for any Social Network Content.
                    </span>

                    <span className="tos-text-subtext">
                        You acknowledge and agree that we may access your email address book
                        associated with a Third-Party Account and your contacts list stored on your
                        mobile device or tablet computer solely for purposes of identifying and
                        informing you of those contacts who have also registered to use the
                        Platform.
                    </span>

                    <span className="tos-text-subtext">
                        You can deactivate the connection between the Platform and your Third-Party
                        Account by contacting us using the contact information below or through your
                        account settings (if applicable). We will attempt to delete any information
                        stored on our servers that was obtained through such Third-Party Account,
                        except the username and profile picture that become associated with your
                        account.
                    </span>
                </li>

                <li>
                    <span className="tos-text">
                        <strong>Third-party websites and content:</strong> The Platform may contain
                        (or you may be sent via the Platform) links to other websites (“Third-Party
                        Websites”) as well as articles, photographs, text, graphics, pictures,
                        designs, music, sound, video, information, applications, software, and other
                        content or items belonging to or originating from third parties
                        (“Third-Party Content”).
                    </span>

                    <span className="tos-text-subtext">
                        Such Third-Party Websites and Third-Party Content are not investigated,
                        monitored, or checked for accuracy, appropriateness, or completeness by us,
                        and we are not responsible for any Third-Party Websites accessed through the
                        Platform or any Third-Party Content posted on, available through, or
                        installed from the Platform, including the content, accuracy, offensiveness,
                        opinions, reliability, privacy practices, or other policies of or contained
                        in the Third-Party Websites or the Third-Party Content.
                    </span>

                    <span className="tos-text-subtext">
                        Inclusion of, linking to, or permitting the use or installation of any
                        Third-Party Websites or any Third-Party Content does not imply approval or
                        endorsement thereof by us. If you decide to leave the Platform and access
                        the Third-Party Websites or to use or install any Third-Party Content, you
                        do so at your own risk, and you should be aware these Terms of Service no
                        longer govern.
                    </span>

                    <span className="tos-text-subtext">
                        You should review the applicable terms and policies, including privacy and
                        data gathering practices, of any website to which you navigate from the
                        Platform or relating to any applications you use or install from the
                        Platform. Any purchases you make through Third-Party Websites will be
                        through other websites and from other companies, and we take no
                        responsibility whatsoever in relation to such purchases which are
                        exclusively between you and the applicable third party.
                    </span>

                    <span className="tos-text-subtext">
                        You agree and acknowledge that we do not endorse the products or services
                        offered on Third-Party Websites and you shall hold us harmless from any harm
                        caused by your purchase of such products or services. Additionally, you
                        shall hold us harmless from any losses sustained by you or harm caused to
                        you relating to or resulting in any way from any Third-Party Content or any
                        contact with Third-Party Websites.
                    </span>
                </li>

                <li>
                    <span className="tos-text">
                        <strong>Media.</strong> The media on Earth Hero platforms were generously
                        made available for free or were directly contributed by various artists
                        (referred here further as “Contributors”). Most media that we display are
                        within the public domain or are copyrighted with an associated Creative
                        Commons (CC) license (www.creativecommons.org). Acknowledgements for
                        contributions are posted.
                    </span>

                    <span className="tos-text-subtext">
                        (1) <strong>Copyrights/Creative Licenses:</strong> All persons reproducing,
                        redistributing, or making commercial use of these data and media resources
                        are expected to adhere to the terms and conditions asserted by the copyright
                        holder and provided with each resource. Transmission or reproduction of
                        protected items beyond that asserted, or that is allowed by fair use as
                        defined by U.S. copyright law, requires the written permission of the
                        copyright owners.
                    </span>

                    <span className="tos-text-subtext">
                        (2) <strong>Media Privacy and Publicity Rights:</strong> Unless noted
                        specifically with an image, Earth Hero does not know of the existence of a
                        model release statement or other agreements for any person featured in a
                        photograph in the Earth Hero platforms, and thus does not know whether the
                        person has in any way agreed to have their photograph used for nonprofit
                        use, publicity campaigns, or commercial uses. Please contact the original
                        source for permission.
                    </span>

                    <span className="tos-text-subtext">
                        (3) <strong>Liability:</strong> If you download any media and use in a
                        manner inconsistent with the rights and licenses provided, via this document
                        and U.S. and international laws, Earth Hero will be held harmless and will
                        not be legally responsible for any and all out-of-pocket costs arising from
                        any resulting violations(s) of property rights, privacy rights and copyright
                        rights including, but not limited to judgments, settlements and attorneys’
                        fees.
                    </span>

                    <span className="tos-text-subtext">
                        (4) <strong>Responsibility for Use:</strong> It is the sole responsibility
                        of you, the user of this site, to carefully examine the content provided for
                        copyright restrictions and to secure all necessary permissions for each
                        media resource.
                    </span>

                    <span className="tos-text-subtext">
                        <strong>Other Content</strong>
                    </span>

                    <span className="tos-text-subtext">
                        (1) <strong>Copyrights:</strong> All textual descriptions provided by Earth
                        Hero for actions and content within the Earth Hero platforms are created by
                        Earth Hero and are held under a CC license of CC-BY 4.0
                        (https://creativecommons.org/licenses/by/4.0/). You may use them freely as
                        long as you provide with attribution to Earth Hero. See the license for more
                        detail.
                    </span>

                    <span className="tos-text-subtext">
                        (2) <strong>Credit/Citation:</strong> Please utilize the following credit
                        line:
                    </span>

                    <span className="tos-text-subtext">
                        Resource/Content provided by the EarthHero Non-Profit Organization –{' '}
                        <a href="https://www.earthhero.org">https://www.earthhero.org</a>
                    </span>
                </li>

                <li>
                    <span className="tos-text">
                        <strong>Digital Millennium Copyright Act (DCMA) Notice and Policy:</strong>{' '}
                        We respect the intellectual property rights of others. If you believe that
                        any material available on or through the Platform infringes upon any
                        copyright you own or control, please immediately notify us using the contact
                        information provided below (a “Notification”).
                    </span>

                    <span className="tos-text-subtext">
                        A copy of your Notification will be sent to the person who posted or stored
                        the material addressed in the Notification. Please be advised that pursuant
                        to federal law you may be held liable for damages if you make material
                        misrepresentations in a Notification. Thus, if you are not sure that
                        material located on or linked to by the Platform infringes your copyright,
                        you should consider first contacting an attorney.
                    </span>

                    <span className="tos-text-subtext">
                        All Notifications should meet the requirements of DMCA 17 U.S.C. § 512(c)(3)
                        and include the following information:
                    </span>

                    <span className="tos-text-subtext">
                        (1) A physical or electronic signature of a person authorized to act on
                        behalf of the owner of an exclusive right that is allegedly infringed;
                    </span>

                    <span className="tos-text-subtext">
                        (2) identification of the copyrighted work claimed to have been infringed,
                        or, if multiple copyrighted works on the Platform are covered by the
                        Notification, a representative list of such works on the Platform;
                    </span>

                    <span className="tos-text-subtext">
                        (3) identification of the material that is claimed to be infringing or to be
                        the subject of infringing activity and that is to be removed or access to
                        which is to be disabled, and information reasonably sufficient to permit us
                        to locate the material;
                    </span>

                    <span className="tos-text-subtext">
                        (4) information reasonably sufficient to permit us to contact the
                        complaining party, such as an address, telephone number, and, if available,
                        an email address at which the complaining party may be contacted;
                    </span>

                    <span className="tos-text-subtext">
                        (5) a statement that the complaining party has a good faith belief that use
                        of the material in the manner complained of is not authorized by the
                        copyright owner, its agent, or the law;
                    </span>

                    <span className="tos-text-subtext">
                        (6) a statement that the information in the notification is accurate, and
                        under penalty of perjury, that the complaining party is authorized to act on
                        behalf of the owner of an exclusive right that is allegedly infringed upon.
                    </span>

                    <span className="tos-text-subtext">Counter Notification</span>

                    <span className="tos-text-subtext">
                        If you believe your own copyrighted material has been removed from the
                        Platform as a result of a mistake or misidentification, you may submit a
                        written counter notification to [us/our Designated Copyright Agent] using
                        the contact information provided below (a “Counter Notification”).
                    </span>

                    <span className="tos-text-subtext">
                        To be an effective Counter Notification under the DMCA, your Counter
                        Notification must include substantially the following:
                    </span>

                    <span className="tos-text-subtext">
                        (1) identification of the material that has been removed or disabled and the
                        location at which the material appeared before it was removed or disabled;
                    </span>

                    <span className="tos-text-subtext">
                        (2) a statement that you consent to the jurisdiction of the Federal District
                        Court in which your address is located, or if your address is outside the
                        United States, for any judicial district in which we are located;
                    </span>

                    <span className="tos-text-subtext">
                        (3) a statement that you will accept service of process from the party that
                        filed the Notification or the party’s agent;
                    </span>

                    <span className="tos-text-subtext">
                        (4) your name, address, and telephone number;
                    </span>

                    <span className="tos-text-subtext">
                        (5) a statement under penalty of perjury that you have a good faith belief
                        that the material in question was removed or disabled as a result of a
                        mistake or misidentification of the material to be removed or disabled;
                    </span>

                    <span className="tos-text-subtext">
                        (6) your physical or electronic signature.
                    </span>

                    <span className="tos-text-subtext">
                        If you send us a valid, written Counter Notification meeting the
                        requirements described above, we will restore your removed or disabled
                        material, unless we first receive notice from the party filing the
                        Notification informing us that such party has filed a court action to
                        restrain you from engaging in infringing activity related to the material in
                        question.
                    </span>

                    <span className="tos-text-subtext">
                        Please note that if you materially misrepresent that the disabled or removed
                        content was removed by mistake or misidentification, you may be liable for
                        damages, including costs and attorney’s fees. Filing a false Counter
                        Notification constitutes perjury.
                    </span>

                    <span className="tos-text-subtext">
                        Designated Copyright Contact –{' '}
                        <a href="mailto:contact@earthhero.org">contact@earthhero.org</a>
                    </span>
                </li>

                <li>
                    <span className="tos-text">
                        <strong>Copyright Infringements:</strong> We respect the intellectual
                        property rights of others. If you believe that any material available on or
                        through the Platform infringes upon any copyright you own or control, please
                        immediately notify us using the contact information provided below (a
                        “Notification”). A copy of your Notification will be sent to the person who
                        posted or stored the material addressed in the Notification. Please be
                        advised that pursuant to federal law you may be held liable for damages if
                        you make material misrepresentations in a Notification. Thus, if you are not
                        sure that material located on or linked to by the Platform infringes your
                        copyright, you should consider first contacting an attorney.
                    </span>
                </li>

                <li>
                    <span className="tos-text">
                        <strong>Corrections:</strong> There may be information on the Platform that
                        contains typographical errors, inaccuracies, or omissions that may relate to
                        the Platform, including descriptions, pricing, availability, and various
                        other information. We reserve the right to correct any errors, inaccuracies,
                        or omissions and to change or update the information on the Platform at any
                        time, without prior notice.
                    </span>
                </li>

                <li>
                    <span className="tos-text">
                        <strong>LIMITATIONS OF LIABILITY</strong>
                    </span>

                    <span className="tos-text-subtext">
                        IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU
                        OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY,
                        INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST
                        REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE,
                        EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                    </span>
                </li>

                <li>
                    <span className="tos-text">
                        <strong>Indemnification:</strong> You agree to defend, indemnify, and hold
                        us harmless, including our subsidiaries, affiliates, and all of our
                        respective officers, agents, partners, and employees, from and against any
                        loss, damage, liability, claim, or demand, including reasonable attorneys’
                        fees and expenses, made by any third party due to or arising out of: (1)
                        your Contributions; (2) use of the Platform; (3) breach of these Terms of
                        Service; (4) any breach of your representations and warranties set forth in
                        these Terms of Service; (5) your violation of the rights of a third party,
                        including but not limited to intellectual property rights; or (6) any overt
                        harmful act toward any other user of the Platform with whom you connected
                        via the Platform. Notwithstanding the foregoing, we reserve the right, at
                        your expense, to assume the exclusive defense and control of any matter for
                        which you are required to indemnify us, and you agree to cooperate, at your
                        expense, with our defense of such claims. We will use reasonable efforts to
                        notify you of any such claim, action, or proceeding which is subject to this
                        indemnification upon becoming aware of it.
                    </span>
                </li>

                <li>
                    <span className="tos-text">
                        <strong>User Data:</strong> We will maintain certain data that you transmit
                        to the Platform for the purpose of managing the Platform, as well as data
                        relating to your use of the Platform. Although we perform regular routine
                        backups of data, you are solely responsible for all data that you transmit
                        or that relates to any activity you have undertaken using the Platform.
                    </span>
                </li>
            </ol>
            <span className="tos-text">
                You agree that we shall have no liability to you for any loss or corruption of any
                such data, and you hereby waive any right of action against us arising from any such
                loss or corruption of such data.
            </span>

            <span className="tos-text">
                Thank you. If you encounter technical problems or have questions, please contact us
                at <a href="mailto:contact@earthhero.org">contact@earthhero.org</a>.
            </span>
            <span className="updated-at">Last Updated: Wednesday, April 29, 2020</span>
        </div>
    </Layout>
);

export default TermsOfService;
